import jason from "../images/jason.jpg";

const Home = () => {
  return (
    <div className="content">
      <h1>Jason Frazier's Portfolio</h1>
      <div className="picture">
        <img src={jason} alt="Jason's Picture" />
      </div>
      <p className="code">
        Resourceful and detail-oriented front-end engineer looking for a
        challenging position in web development; exceptional candidate with an
        expert knowledge of the JavaScript ecosystem including NodeJS; possesses
        highly-relevant skill set for turning designs and requirements into
        responsive and tested production websites; an excellent communicator
        with natural organizational skills.
      </p>
    </div>
  );
};

export default Home;
