const Technical = () => {
  return (
    <div className="content">
      <h1>TECHNICAL SKILLS</h1>

      <div className="skills">
        <h2>Languages</h2> <p>JavaScript, PHP, CSS, HTML</p>
      </div>
      <div className="skills">
        <h2>Frameworks</h2>{" "}
        <p>Node.js, Express, Mocha, jQuery, LESS, Handlebars, etc.</p>
      </div>
      <div className="skills">
        <h2>Libraries</h2>{" "}
        <p>
          React, Redux, GraphQL, Typescript, Lodash, Underscore, Browserify, D3,
          Chai, Sinon, Gulp, Babel, Webpack, Storybook, etc.
        </p>
      </div>
      <div className="skills">
        <h2>Technologies</h2>{" "}
        <p>
          Git, Yarn, npm, VSCode, Vim, Terminal, Photoshop, Illustrator, etc.
        </p>
      </div>
      <div className="skills">
        <h2>OS</h2> <p>Linux, MacOS, Windows</p>
      </div>
    </div>
  );
};

export default Technical;
