const Contact = () => {
  return (
    <div className="content contact">
      <h1>Contact Me</h1>
      <p>
        <a href="https://linkedin.com/in/frazierjason">LinkedIn</a>
      </p>
    </div>
  );
};

export default Contact;
