const Portfolio = () => {
  return (
    <div className="content">
      <h1>Portfolio</h1>
      <article>
        <div className="company">Twitter</div>
        <div className="date">2020 to 2023</div>
        <div className="position">Front End Web Engineer</div>
        <div className="articlebody">
          <p>
            At Twitter, I worked in the Customer Revenue Org, which brought in
            $5billion in revenue in 2022 and was projected to bring in $6billion
            in revenue for 2023. During this time, I scoped, planned, and
            developed the front-end experience which involved creating ad
            formats for use on the Twitter timeline. In this role, I used
            ReactJS, Typescript, GraphQL, NodeJS, Jest, Storybook, and other
            technologies, libraries, and frameworks. I am especially proud of
            this work since Twitter was far behind their competitors, especially
            Facebook and Google, both in terms of ad technology and revenue.
          </p>
          <p>
            I also managed the A/B testing of ad-related features deployed to
            production, in order to test the efficacy of the feature as well as
            catch any bugs before the feature was deployed to all users.
          </p>
          <p>
            Before that, I worked on the Goldbird Team, which was also involved
            in revenue generation for Twitter. On this team, I planned and
            implemented the retooling of our front-end infrastructure.
            Specifically, moving from using Redux to using GraphQL, as well as
            helping to migrate from Flow to Typescript. Moving to GraphQL was
            important as it allowed more flexibility for our back-end engineers
            in the implementing of data structures, especially when changes
            needed to be made. Moving to Typescript was important because
            Twitter originally developed Flow, but Typescript became the
            industry-standard, so the migration helped to make the code base
            more maintainable while also keeping engineer skillsets relevant
          </p>
        </div>
      </article>
      <hr />
      <article>
        <div className="company">CrossInstall</div>
        <div className="date">2017 to 2020</div>
        <div className="position">Front End Web Engineer</div>
        <div className="articlebody">
          <p>
            CrossInstall was a company, acquired by Twitter, which developed
            mobile ads for video games, which were they themselves video games.
            This technology set us apart from competitors, which at the time
            were using static images or video instead. I took this position
            because of the challenges of working with pure, vanilla JavaScript.
            We only used one third-party library, Phaser, to develop the video
            game ads. The other libraries were written in-house by myself and
            our small, four person team. NodeJS was used as a build tool to
            generate the smallest files possible for delivery. Gulp, and later
            Webpack, were used in the process, as well as many smaller libraries
            which were usually forked and refactored as necessary.
          </p>
          <p>
            The reason for this is that the games needed to run, not only on as
            many different mobile devices as possible, but also on desktop for
            previewing by our clients. I was responsible for not only
            maintaining the existing infrastructure, but for rewriting and
            expanding the functionality of the codebase to fit the ever-changing
            needs of the ad industry.
          </p>
          <p>
            One of the projects I am most proud of is migrating the older parts
            of our codebase to use ECMA6 standards so that Webpack could be used
            during the build process instead of Gulp. This was important because
            the final deliverable ad needed to be as small as possible, usually
            under 3mb, and in some cases under 1mb! Webpack is better at
            understanding which code branches are not used in the current ad,
            which allowed those code branches to be removed, making the file
            size as small as possible.
          </p>
          <p>
            In another project for Twitch, one of our biggest clients, I rewrote
            the entire framework to allow in-game ads to show live Twitch
            streams instead of our normal video games. I am proud of this
            project because it was the first time in the industry that live
            streams were used for not only advertising games, but also products,
            such as the live unboxing videos which are popular today.
          </p>
          <p>
            Finally, I would like to highlight a project that I scoped, planned,
            developed, and implemented which had a high tangible impact on the
            quality of our products. I migrated the company’s gaming
            infrastructure from using HTML audio to the more advanced WebAudio.
            This allowed for much more flexibility and richness in the sound
            quality of the games we produced.
          </p>
        </div>
      </article>
      <hr />
      <article>
        <div className="company">Lending Club</div>
        <div className="date">2016 to 2017</div>
        <div className="position">Front End Web Engineer</div>
        <div className="articlebody">
          <p>
            At Lending Club, I worked as a front-end engineer on the Investor
            Team. This team was responsible for revenue generation for the
            company. The project I am most proud of is implementing the
            migration of the front end from a JQuery-based, multi-page Jason
            Frazier Page 3 Resume architecture to a one-page web application.
            This new application was based on an in-house framework, which was
            basically a fork of Express (Node.js server framework) and Cartero,
            which was itself a fork of Webpack. It was here that I learned how
            to write responsive, one-page web apps that used a grid system
            designed in CSS. I also learned how to set up A/B and multivariant
            testing, which was useful in determining if new features reduced or
            increased friction in the investor onboarding funnel.
          </p>
          <p>
            Another project that I worked on was moving from a monolithic
            codebase to a microservice-based architecture. This was especially
            important in maintaining the responsiveness of the one-page web app,
            as well as the maintainability of the codebase itself. This required
            the development of a “stateless” front end, which would load into
            the user’s browser first, followed by a “hydration” phase, as the
            data was loading from different microservices. I helped architect
            and build out a “mid-tier” backend in NodeJS. This allowed the
            managing of microservice calls coming from a multitude of Java
            backend systems.
          </p>
          <p>
            Along with these achievements, I was also trained in web security to
            understand how attackers might use the front end to access our
            internal infrastructure. I was also trained in AWS, which I used to
            monitor A/B and multivariant tests, as well as debug deployment
            issues.
          </p>
        </div>
      </article>
      <hr />
      <article>
        <div className="company">Telegraph Hill Software</div>
        <div className="date">2014 to 2016</div>
        <div className="position">Front End Web Engineer</div>
        <div className="articlebody">
          <p>
            I worked as an embedded consultant at Lending Club through Telegraph
            Hill Software. While working as a consultant, I had to wear several
            different hats. While I was working on the Investor Team, I helped
            bring millions of dollars onto the platform. My main duty was to
            work with the Product Manager, as well as the Design team, to
            integrate designs and specifications into ads and marketing landing
            pages.
          </p>
          <p>
            As another part of my job, I implemented investor onboarding flows
            to bring new investors onto the platform. This work is what led to
            my direct hiring by Lending Club.
          </p>
          <p>
            Finally, I was responsible for interviewing and hiring other
            front-end engineers. This was important because the company was
            growing quickly and needed to maintain pace with our competitors.
          </p>
        </div>
      </article>
    </div>
  );
};

export default Portfolio;
