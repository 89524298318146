import { Outlet, Link } from "react-router-dom";
import { bubble as Menu } from "react-burger-menu";
import { useState } from "react";

const Layout = () => {
  const [state, setState] = useState({ menuOpen: false });
  const closeMenu = () => {
    this.setState({ menuOpen: false });
  };
  return (
    <main>
      <Menu>
        <ul>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/portfolio" onClick={closeMenu}>
              Portfolio
            </Link>
          </li>
          <li>
            <Link to="/technical" onClick={closeMenu}>
              Technical Skills
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </Menu>

      <div className="maincontent">
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
